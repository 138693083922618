// @flow

import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reset, change } from 'redux-form'
import { Map } from 'immutable'
import bindAll from "lodash/bindAll";
import imgAstronautLogo from '../../images/logo-black.svg'
import UserRegisterWizardFormFirstPageNew from './users/UserRegisterWizardFormFirstPageNew'
import UserRegisterWizardFormSecondPageNew from './users/UserRegisterWizardFormSecondPageNew'
import UserRegisterWizardFormThirdPageNew from './users/UserRegisterWizardFormThirdPageNew'
import initializers from '../../dependencies/initializers'
import { usersAddNavigationPage } from '../../actions/users'
import { globalBlockingTransition, translate } from '../../helpers/global'

import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'

import AstrntFavicon from '../../styles/img/favicon.png'
import imgOfficeGirl from '../../styles/img/office-girl.svg'

import imgStudentAdmissions from '../../styles/img/discuss-talent.svg'
import imgCandidateSelection from '../../styles/img/hiring.svg'
import imgInterviewJob from '../../styles/img/interview-job.svg'
import imgCompanyBuilding from '../../styles/img/company-building.svg'
import imgDataCollection from '../../styles/img/data-collection.svg'
import imgPeopleSearch from '../../styles/img/people-search.svg'

type Props = {
	resetForm: Function,
	usersAddNavigationPage: Function,
	usersNavigationData: Map<string, any>
};

type State = {
	unblockTransition: Function,
	cachedUsersNavigationDataPage: string,
	option: String,
	setOption: Function,
	nextPage: Function,
	prevPage: Function
};

class UserRegisterWizardFormContainerNew extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			unblockTransition: () => {},
			cachedUsersNavigationDataPage: '',
			option: [],
			setOption: (val) => { this.setState({option: val}) },
			nextPage: () => {},
			prevPage: () => {},
			tempSegmentType:[]
		}

		bindAll(this, [
			"handleContinueClick",
			"handleClickHereClick",
			"handleSelectSegmentType"
		]);
	}

	componentDidMount() {
		const titleElement: Object = document.getElementsByTagName('title')

		titleElement[0].text = 'ASTRNT'
		window.scrollTo(0, 0);
	    
	    $('.overlay, .close-button').click(function(event){
	        event.preventDefault();
	        
	        $('#pop-1').slideUp('fast');
	        $('#pop-2').slideUp('fast');
	        $('.overlay').fadeOut('fast');
	    });

		if('es1t' === this.props.plan) {
	  	  $('#pop-1').css('display', 'block')
	  	  $('.overlay').fadeIn('fast');
		  $('.div-info').css('display', 'none')
		}

		if('es2t' === this.props.plan) {
	  	  $('#pop-1').css('display', 'block')
	  	  $('.overlay').fadeIn('fast');
		  $('.div-info').css('display', 'none')
		}

		if('ngo' === this.props.plan) {
	  	  $('#how-do-you-hope').css('display', 'list-item')
		}
	}

	componentWillUnmount() {
		// this.state.unblockTransition()
		const {
			resetForm,
			usersAddNavigationPage
		} = this.props

		resetForm('UserRegisterWizardForm')
		usersAddNavigationPage(1)
	}

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			usersNavigationData,
			usersAddNavigationPage,
			url_location,
			history
		} = nextProps

		if (usersNavigationData.get('page') !== prevState.cachedUsersNavigationDataPage) {
			let page: number = usersNavigationData.get('page')

			const nextPage: Function = () => {
				usersAddNavigationPage(page + 1)
			}

			const previousPage: Function = () => {
				usersAddNavigationPage(page - 1)
			}

			switch(page) {
				case 2:
					// history.push('/registered')
					window.dataLayer.push({
						event: 'registered',
						page: {
						  url: url_location,
						  title: 'registered success'
						}
					  });
					return {
						cachedUsersNavigationDataPage: page,
					}
				default:
					return {
						cachedUsersNavigationDataPage: page,
						prevPage: previousPage,
						nextPage: nextPage,
					}
			}
		}

		return null
	}

	chooseOpt(val) {
	  if( val === 'I’m An Applicant' ) {
		$('.div-info').css('display', 'block')
		$('.box-option-1').removeClass('selected')
		$('.box-option-2').removeClass('selected')
		$('.box-option-3').addClass('selected')
	  } else if (val === 'Recruitment & Assessment') {
		$('.div-info').css('display', 'none')
		// $('#pop-1').css('display', 'none')
		$('.box-option-1').removeClass('selected')
		$('.box-option-2').addClass('selected')
		$('.box-option-3').removeClass('selected')
	  } else {
	  	$('.div-info').css('display', 'none')
	  	// $('#pop-1').css('display', 'none')
		$('.box-option-1').addClass('selected')
		$('.box-option-2').removeClass('selected')
		$('.box-option-3').removeClass('selected')
	  }

	  $('div.select-plan select').val(val).change();
	  this.props.changeFieldValue('how_you_use_astrnt', val)
	  this.setState({option: val})
	}

	handleContinueClick() {
		let option = this.state.tempSegmentType
		let segmentTypeOptions = [
			{value: "Talent Acquisition", label: "Talent Acquisition", description:'Hire top talent efficiently. Make data-driven hiring decisions.'},
			{value: "Talent Management", label: "Talent Management", description:'Develop and retain high-performing employees. Build a strong workforce.'},
			{value: "Small Medium Enterprise", label: "Small Medium Enterprise", description:'Hire the right people to grow your business. Optimize hiring processes.'},
			{value: "University Admission", label: "University Admission", description:'Select qualified students for enrollment. Build a diverse student body.'},
			{value: "Career Development Center", label: "Career Development Center", description:'Prepare students for successful careers. Measure student outcomes.'}
		]

		let selectedSegmentType = segmentTypeOptions.filter(x=> option.includes(x.value))

	  	this.setState({option: selectedSegmentType})
		let segmentTypeValue = option.join(',')
	  	this.props.changeFieldValue('segment_type', segmentTypeValue)

		$('#pop-1').css('display', 'none')
		$('.overlay').fadeOut('fast');
	}

	handleClickHereClick() {
		$('#pop-1').css('display', 'none')
		$('#pop-2').css('display', 'block')
		$('.div-info').css('display', 'block')
	}

	handleSelectSegmentType(val){
		let option = this.state.tempSegmentType
	  	if(option.includes(val)){
			option = option.filter(x=> x!=val)
		}else{
			option.push(val)
	  	}

	  	this.setState({tempSegmentType: option})
	}

	render() {
		const {
			globalGetLanguages,
			plan,
		} = this.props

		const {
			tempSegmentType
		} = this.state
		
		return (
			<div id="register">

			<div id="pop-1" className="pop" style={{display: 'none'}}>
			  <div className="pop-head">
			    {translate(globalGetLanguages,'REGISTER_POP_WHAT_BEST')}
			    <a className="close-button" href="#"><i className="fa fa-times" /></a>
			  </div>
			  <hr/>
			  <div className="pop-body">
			  	{/*<label>We help in many ways, and want to direct you correctly.</label>*/}
			  	<div className="div-selection horizontal-item--between">
			  		<div className="box-option box-option-1">
					  	<div className='img-container'><img src={imgCandidateSelection} /></div>
			  			<span>Talent Acquisition</span>
						<p>Hire top talent efficiently. Make data-driven hiring decisions.</p>
						<label className="inline normal checkbox-container">
							<input
								type="checkbox"
								checked={tempSegmentType.includes('Talent Acquisition')}
								class="checkbox"
								onClick={()=>this.handleSelectSegmentType('Talent Acquisition')}
							/><span className="checkmark"></span>
						</label>
			  		</div>
			  		<div className="box-option box-option-2" >
			  			<div className='img-container'><img src={imgPeopleSearch} /></div>
			  			<span>Talent Management</span>
						<p>Develop and retain high-performing employees. Build a strong workforce.</p>
						<label className="inline normal checkbox-container">
							<input
								type="checkbox"
								checked={tempSegmentType.includes('Talent Management')}
								class="checkbox"
								onClick={()=>this.handleSelectSegmentType('Talent Management')}
							/><span className="checkmark"></span>
						</label>
			  		</div>
			  		<div className="box-option box-option-3" >
					  	<div className='img-container'><img src={imgCompanyBuilding} /></div>
			  			<span>Small Medium Enterprise</span>
						<p>Hire the right people to grow your business. Optimize hiring processes.</p>
						<label className="inline normal checkbox-container">
							<input
								type="checkbox"
								checked={tempSegmentType.includes('Small Medium Enterprise')}
								class="checkbox"
								onClick={()=>this.handleSelectSegmentType('Small Medium Enterprise')}
							/><span className="checkmark"></span>
						</label>
			  		</div>
					  <div className="box-option box-option-4" >
					  	<div className='img-container'><img style={{width: '133px', height: '94px'}} src={imgStudentAdmissions} /></div>
			  			<span>University Admission</span>
						<p>Select qualified students for enrollment. Build a diverse student body.</p>
						<label className="inline normal checkbox-container">
							<input
								type="checkbox"
								checked={tempSegmentType.includes('University Admission')}
								class="checkbox"
								onClick={()=>this.handleSelectSegmentType('University Admission')}
							/><span className="checkmark"></span>
						</label>
			  		</div>
			  		<div className="box-option box-option-5" >
					  	<div className='img-container'><img src={imgDataCollection} /></div>
			  			<span>Career Development Center</span>
						<p>Prepare students for successful careers. Measure student outcomes.</p>
						<label className="inline normal checkbox-container">
							<input
								type="checkbox"
								checked={tempSegmentType.includes('Career Development Center')}
								class="checkbox"
								onClick={()=>this.handleSelectSegmentType('Career Development Center')}
							/><span className="checkmark"></span>
						</label>
			  		</div>
			  	</div>
				<div className='m-t-32'>
					<button disabled={this.state.tempSegmentType.length==0} onClick={this.handleContinueClick} className='btn-continue-pop'>Continue</button>
				</div>
				<p className='applicant-p'>
					Or are you an applicant? <a onClick={this.handleClickHereClick}>Click here</a>
				</p>
			  </div>
			</div>

			<div id="pop-2" className="pop" style={{display: 'none'}}>
			  <div className="pop-head">
			    {translate(globalGetLanguages,'REGISTER_POP_OK_SUPER')}
			    <a className="close-button" href="#"><i className="fa fa-times" /></a>
			  </div>
			  <hr/>
			  <div className="pop-body">
			  	<div className={`div-info`}>
			  		<p>{translate(globalGetLanguages,'REGISTER_POP_APPLICANTS_SHOULD')}</p>
			  		<ol>
			  			<li>{translate(globalGetLanguages,'REGISTER_POP_CHECK_EMAIL')}</li>
						<li>{translate(globalGetLanguages,'REGISTER_POP_INSERT_CODE')}<Link to={`/enter-code`}>ASTRNT</Link>.</li>
						<li>{translate(globalGetLanguages,'REGISTER_POP_START_INTERVIEW')}</li>
			  		</ol>
			  		<p>{translate(globalGetLanguages,'REGISTER_POP_NEED_ASSISTANCE')}<a href="mailto:support@astrnt.co" target="_blank">{translate(globalGetLanguages,'REGISTER_POP_SUPPORT_TEAM')}</a></p>
			  	</div>
			  </div>
			</div>

			<div id="pop-3" className="pop pop-dropdown" style={{display: 'none'}}>
			  <div className="pop-head">
			  	{translate(globalGetLanguages,'REGISTER_POP_WHAT_BEST')}
			    <a className="close-button" href="#"><i className="fa fa-times" /></a>
			  </div>
			  <div className="pop-body">
			  	<div className="select-plan-modal">
					<label className="inline normal checkbox-container">
						<input
							type="checkbox"
							checked={false}
							class="checkbox"
						/>Talent Acquisition<span className="checkmark"></span>
						<div><p>Hire top talent efficiently. Make data-driven hiring decisions.</p></div>
						<hr />
					</label>
					<label className="inline normal checkbox-container">
						<input
							type="checkbox"
							checked={true}
							class="checkbox"
						/>Talent Management<span className="checkmark"></span>
						<div><p>Develop and retain high-performing employees. Build a strong workforce.</p></div>
						<hr />
					</label>
					<label className="inline normal checkbox-container">
						<input
							type="checkbox"
							checked={false}
							class="checkbox"
						/>Small Medium Enterprise<span className="checkmark"></span>
						<div><p>Hire the right people to grow your business. Optimize hiring processes.</p></div>
						<hr />
					</label>
					<label className="inline normal checkbox-container">
						<input
							type="checkbox"
							checked={false}
							class="checkbox"
						/>University Admission<span className="checkmark"></span>
						<div><p>Select qualified students for enrollment. Build a diverse student body.</p></div>
						<hr />
					</label>
					<label className="inline normal checkbox-container">
						<input
							type="checkbox"
							checked={false}
							class="checkbox"
						/>Career Development Center<span className="checkmark"></span>
						<div><p>Prepare students for successful careers. Measure student outcomes.</p></div>
					</label>
				</div>
			  </div>
			</div>

			<div className="overlay" style={{display: 'none'}} />

	        <HeaderNewClear />
	        <div className="pad-top desktop-hide" />
	        <section className="sec-reg">
	          <ul className="lay-reg">
	            <li>
	              <div className="desc-log">
	                <span>Bring structure, confidence, speed and ease to your recruitment & selection processes.</span>
	                {translate(globalGetLanguages,'REGISTER_PAGE_JOIN_HUNDREDS')}
	                <ul>
	                  <li><i className="fas fa-check" />Easy start, no credit card</li>
	                  <li><i className="fas fa-check" />Always free, candidate tracking</li>
	                  <li><i className="fas fa-check" />{translate(globalGetLanguages,'REGISTER_PAGE_UNLIMITED_JOBS')}</li>
	                  <li><i className="fas fa-check" />Unlimited team members, no limits</li>
	                  <li><i className="fas fa-check" />Chat anytime with our team<br/>of selection experts</li>
	                </ul>
	                <img src={imgOfficeGirl} />
	              </div>
	            </li>
	            <li>
	              <div className="box-reg">
					{
						this.state.cachedUsersNavigationDataPage == '2' ? 
							<UserRegisterWizardFormThirdPageNew /> 
							: 
							<UserRegisterWizardFormFirstPageNew
								previousPage={this.state.prevPage}
								onSubmit={this.state.nextPage}
								setOption={this.state.setOption}
								option={this.state.option}
								plan={this.props.plan} />
					}
	              </div>
	            </li>
	          </ul>
	        </section>
	        <FooterNew />
	      </div>
    )
	}
}

const mapStateToProps = (state, ownProps) => {
	let plan = 'es2t' // default plan
	let params = new URLSearchParams(window.location.search)
	if(ownProps?.location?.search && params.get('plan')) {
		plan = params.get('plan')
	} else if(ownProps?.location?.query?.plan) {
		plan = ownProps.location.query.plan
	}
	
	return {
		usersNavigationData: state.usersNavigationData,
		plan: plan,
		url_location:ownProps.location,
		globalGetLanguages:state.globalGetLanguages,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetForm: (formName) => dispatch(reset(formName)),
		usersAddNavigationPage: (page) => dispatch(usersAddNavigationPage(page)),
		changeFieldValue: (field, value) => dispatch(change('UserRegisterWizardForm', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterWizardFormContainerNew)
