// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import initializers from '../../../dependencies/initializers'
import AstrntFavicon from '../../../styles/img/favicon.png'

import {
	Field,
	reduxForm,
  change
} from 'redux-form'

import RenderInputV2 from './RenderInputV2'
import RenderSelectAstrntUse from './RenderSelectAstrntUse'
import RenderReactSelect from './RenderReactSelect'
import RenderTextareaV2 from './RenderTextareaV2'

import validateForUserRegisterWizardForm from './validateForUserRegisterWizardForm'

import $ from 'jquery'
import {Prompt} from 'react-router-dom'
import { usersValidateEmailOnUserRegisterWizardFormRequest, usersSaveDataRequest, userVerify } from '../../../actions/users'

import { translate } from '../../../helpers/global'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersValidateEmailOnUserRegisterWizardFormRequest: Function,
	usersSaveDataRequest: Function,
	userVerify: Function,
};

const normalizeEmail = (value, previousValue) => {
	return value.trim()
}

class UserRegisterWizardFormFirstPageNew extends React.Component<Props> {
	constructor(props) {
    super(props)
    
    this.state = {
      isBlocking: false,
      hasUpper:false,
      hasLower:false,
      hasNumber:false,
      hasEightChar:false,
      passwordShown:false,
      isFocusNewPass:false,
      isSelectChange: false,
      selectedSegmentType:[],
      isSubmit: false,
    }

		bindAll(this, [
      'handleEmailChange',
      'handleSaveData',
      'handleChange',
      'onUnload',
      'checkPasswordPolicy',
      'handlePlanChange',
      'handlePasswordReveal',
      'handleSegmentTypeChange'
		])
	}

	handleEmailChange(e) {
    this.handleChange(e)
		this.props.usersValidateEmailOnUserRegisterWizardFormRequest(e.target.value.trim())
  }

  onUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  componentDidUpdate() {
    // this.setState({selectedSegmentType: this.props.option})
    const { isBlocking } = this.state
    if(isBlocking) {
      window.addEventListener('beforeunload', this.onUnload)
    } else {
      window.removeEventListener('beforeunload', this.onUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleChange(e) {
    if(e.target.name == 'password'){
      this.checkPasswordPolicy(e.target.value)
    }

    var isBlocking = false
    $('form input').each(function() {
      if(this.value !== '') {
        isBlocking = true;
        return false;
      }
    })
    this.setState({isBlocking: isBlocking})

  }

  handleSaveData(e) {
    this.setState({isSubmit:true,isFocusNewPass:false})
    this.props.changeFieldValue('plan', this.props.plan)
    let currentUrlParams = new URLSearchParams(window.location.search);
    const utm_campaign = currentUrlParams.get('utm_campaign');
    const utm_source = currentUrlParams.get('utm_source');
    const utm_medium = currentUrlParams.get('utm_medium');
    const host = window.location.host; 
    this.props.changeFieldValue('utm_source', utm_source)
    this.props.changeFieldValue('utm_medium', utm_medium)
    this.props.changeFieldValue('utm_campaign', utm_campaign)
    this.props.changeFieldValue('host', host)
		this.props.usersSaveDataRequest('UserRegisterWizardForm')
  }

  handleLoginLink(e: Object): void {
    e.preventDefault()
      window.location = initializers.recruiter_host+'/auth/login'
  }

  checkPasswordPolicy(val){
    let hasUpper = /^.*(?=.*[A-Z]).*$/.test(val)
    let hasLower = /^.*(?=.*[a-z]).*$/.test(val)
    let hasNumber = /^.*(?=.*\d).*$/.test(val)
    let hasEightChar = val.length >= 8 && val.length <=100 ? true : false 
    
    this.setState({hasLower:hasLower, hasUpper: hasUpper, hasNumber: hasNumber, hasEightChar: hasEightChar})
  }

  handlePlanChange(e) {
    // if('I’m An Applicant' === e.target.value) {
    //   $('#pop-2').css('display', 'block')
    //   $('.div-info').css('display', 'block')
    //   $('.box-option-1').removeClass('selected')
    //   $('.box-option-2').removeClass('selected')
    //   $('.box-option-3').addClass('selected')
    //   $('.overlay').fadeIn('fast');
    // }
    this.props.setOption(e)
  }

  handlePasswordReveal(e) {
    e.preventDefault();


    const { passwordShown } = this.state


    this.setState({
        passwordShown: !passwordShown
    })
  }

  handleSegmentTypeChange(e){
    this.setState({selectedSegmentType: e, isSelectChange:true})
    let segmentTypeValue = e.map((value)=>{
      return value.value
    })

    segmentTypeValue = segmentTypeValue.join(',')
    this.props.changeFieldValue('segment_type', segmentTypeValue)
  }

  render() {
  	const {
  		pristine,
  		submitting,
  		handleSubmit,
  		asyncValidating,
      globalGetLanguages
  	} = this.props

    const {passwordShown, isSubmit} = this.state

    let segmentTypeOptions = [
			{value: "Talent Acquisition", label: "Talent Acquisition", description:'Hire top talent efficiently. Make data-driven hiring decisions.'},
			{value: "Talent Management", label: "Talent Management", description:'Develop and retain high-performing employees. Build a strong workforce.'},
			{value: "Small Medium Enterprise", label: "Small Medium Enterprise", description:'Hire the right people to grow your business. Optimize hiring processes.'},
			{value: "University Admission", label: "University Admission", description:'Select qualified students for enrollment. Build a diverse student body.'},
			{value: "Career Development Center", label: "Career Development Center", description:'Prepare students for successful careers. Measure student outcomes.'}
		]

    let selectedSegmentType = this.props.option
    if(this.state.isSelectChange==true){
      selectedSegmentType = this.state.selectedSegmentType
    }

    return(
    	<Fragment>
        <Prompt
          when={this.state.isBlocking}
          message={location => `Data is not saved! Are you sure you want to leave?`}
        />
        <input name="_token" type="hidden"  value={this.props.csrfToken} />
    		<div className="tit-reg">{translate(globalGetLanguages,'REGISTER_FORM_GET_TO_KNOW')}</div>
        <ul className="the-form">
          <Field
             type="hidden"
             name="utm_source"
             component="input"
              />
          <Field
             type="hidden"
             name="utm_campaign"
             component="input"
              />
          <Field
             type="hidden"
             name="utm_medium"
             component="input"
              />
          <li>
            <Field
              component={RenderReactSelect}
              name="segment_type"
              id="segment_type"
              label={translate(globalGetLanguages,'REGISTER_FORM_WHAT_BEST')}
              change={this.handleSegmentTypeChange}
              options={segmentTypeOptions}
              disabled={isSubmit}
              val={selectedSegmentType}
              />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="company_name"
            onChange={this.handleChange}
            placeholder="e.g: Livit International"
            disabled={isSubmit}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_NAME_COMPANY')} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="email"
            onChange={this.handleEmailChange}
            props={{
              isValidatingEmail: asyncValidating
            }}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_WORK_EMAIL')}
            placeholder="mail@company.com"
            defaultValue=""
            disabled={isSubmit}
            normalize={normalizeEmail} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="mobile_number"
            onChange={this.handleChange}
            // type="number" // this type only works in Chrome
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_WORK_PHONE')}
            placeholder="e.g: +6187823189"
            disabled={isSubmit}
            className="no-spinner" />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="fullname"
            onChange={this.handleChange}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_FULLNAME')}
            disabled={isSubmit}
            placeholder="e.g: Karina Ivanovna" />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="preferred_name"
            onChange={this.handleChange}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_PREFERRED_NAME')}
            disabled={isSubmit}
            placeholder="e.g: Karina Ivanovna" />
          </li>
          <li style={{position: 'relative'}} className="input-tool">
            <Field
            component={RenderInputV2}
            name="password"
            // dontShowError={1}
            onChange={this.handleChange}
            onFocus={()=>{this.setState({isFocusNewPass:1})}}
            onBlur={()=>{this.setState({isFocusNewPass:false})}}
            type={passwordShown ? "text" : "password"}
            defaultValue=""
            PassReqMobile={<span className="pass-req-mobile">at least 8 characters, one number, uppercase and lowercase letters.</span>}
            disabled={isSubmit}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_PASSWORD')} />
            <span className={`tooltiptext ${this.state.isFocusNewPass?'visible':''}`}>
              Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.
              <span className={`pass-req ${ this.state.hasEightChar ? 'active':''}`}><i className="far fa-check-circle"></i>at least 8, max 100 characters</span>
              <span className={`pass-req ${ this.state.hasNumber ? 'active':''}`}><i className="far fa-check-circle"></i>contain a number</span>
              <span className={`pass-req ${ this.state.hasUpper ? 'active':''}`}><i className="far fa-check-circle"></i>contain an uppercase</span>
              <span className={`pass-req ${ this.state.hasLower ? 'active':''}`}><i className="far fa-check-circle"></i>contain a lowercase</span>
            </span>
            <i onClick={this.handlePasswordReveal} className={`fa ${passwordShown ? "fa-eye" : "fa-eye-slash"}`}></i>
          </li>
          <li id="how-do-you-hope" style={{display: 'none'}}>
            <Field
            component={RenderTextareaV2}
            name="objective"
            id="objective"
            onChange={this.handleChange}
            placeholder={translate(globalGetLanguages,'REGISTER_FORM_PLACEHOLDER_I_HOPE')}
            disabled={isSubmit}
            label={translate(globalGetLanguages,'REGISTER_FORM_LABEL_HOW_DO_YOU_HOPE')} />
          </li>
          <li>
            <button
              onClick={handleSubmit(this.handleSaveData)}
              disabled={isSubmit || submitting || pristine || asyncValidating || (this.props.option === 'I’m An Applicant')}
              className="btn-start-trial"
              type="submit">{isSubmit && <i class="fas fa-spinner fa-spin"></i> } {translate(globalGetLanguages,'REGISTER_FORM_SUBMIT_BUTTON')}</button>
          </li>
          <li>
            <div className="center terms-privacy-container">
              {translate(globalGetLanguages,'REGISTER_FORM_BY_CLICK')}<a href={'/termsofservice'} target="_blank" rel="noopener noreferrer" className="underline">Terms of Service</a>{translate(globalGetLanguages,'REGISTER_FORM_AND')}<a href={'/privacypolicy'} target="_blank" rel="noopener noreferrer" className="underline">Privacy Policy</a>.
            </div>
          </li>
          <li>
            <div className="center" style={{fontWeight: '400',fontSize: '14px'}}>
              {translate(globalGetLanguages,'REGISTER_FORM_ALREADY_HAVE')}<a className="cursor-pointer bold" onClick={this.handleLoginLink}>{translate(globalGetLanguages,'REGISTER_FORM_LOGIN')}</a>
            </div>
          </li>
        </ul>
    	</Fragment>
    )
  }
}

UserRegisterWizardFormFirstPageNew = reduxForm({
  form: 'UserRegisterWizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnBlur: true,
  validate: validateForUserRegisterWizardForm,
  onSubmitFail:(errors)=>{
    if(Object.keys(errors).length>0){
      if(document.getElementsByName(Object.keys(errors)[0]).length>0){
        document.getElementsByName(Object.keys(errors)[0])[0].focus()
      }
    }
  }
})(UserRegisterWizardFormFirstPageNew)

const mapStateToProps = (state, ownProps) => {
	return {
    initialValues: {
      _token: state.csrfToken,
      password:'',
      plan: ownProps.plan
    },
		csrfToken: state.csrfToken,
    plan: ownProps.plan,
    globalGetLanguages:state.globalGetLanguages,
    selectedSegmentType: ownProps.selectedSegmentType
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    usersValidateEmailOnUserRegisterWizardFormRequest: (email) => dispatch(usersValidateEmailOnUserRegisterWizardFormRequest(email)),
    usersSaveDataRequest: (formName) => dispatch(usersSaveDataRequest(formName)),
    userVerify: () => dispatch(userVerify()),
    changeFieldValue: (field, value) => dispatch(change('UserRegisterWizardForm', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterWizardFormFirstPageNew)
